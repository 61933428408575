import {
  AuthenticationTokenModel,
  CreateUserCommand,
  LoginUserCommand,
} from '../../models/accountModel';
import {AppDispatch} from '../../store';
import axios from '../../axios';
import {authSlice} from '../../store/account/slices/login.slice';
import {AuthApi} from './constants';
const accountApiUrl = AuthApi.AuthApiPrefix + '/auth';
export const register = (data: CreateUserCommand) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<AuthenticationTokenModel>(
        accountApiUrl + '/registration',
        data,
      );
      dispatch(authSlice.actions.loginSuccess(response.data));
    } catch (e) {
      console.log('Error register', e);
    }
  };
};
export const refreshAccessToken = (refreshToken: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(authSlice.actions.setAuthentication(true));
    try {
      const response = await axios.post<AuthenticationTokenModel>(
        `${accountApiUrl}/refresh`,
        {refreshToken},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      dispatch(authSlice.actions.loginSuccess(response.data));
    } catch (e) {
      console.log('Error refreshing token', e);
      dispatch(authSlice.actions.setAuthentication(false));
      dispatch(authSlice.actions.logout());
    }
  };
};
export const login = (data: LoginUserCommand) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<AuthenticationTokenModel>(accountApiUrl + '/login', data);
      if (response.status >= 300) {
        dispatch(authSlice.actions.loginFailed);
      } else {
        dispatch(authSlice.actions.loginSuccess(response.data));
      }
      return response.data;
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};

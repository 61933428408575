import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import {DateTime} from 'luxon';

import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../../../hooks/redux';
import styles from './secondPage.module.scss';
import {AuthTextInput} from '../../../../pages/auth/components/authInputs/authInputs.component';
import {
  emailValidationConfig,
  passwordValidationConfig,
  simpleTextValidationConfig,
  ValidationConfig,
} from '../../../common/configurations/validationConfigurations';
import {CreateUserCommand} from '../../../../models/accountModel';
import {Control, useController} from 'react-hook-form';
import classBtn from '../buttonsMove.module.scss';
export interface SecondPageRegProperties {
  onUpdateEmail: (email: string) => void;
  onUpdatePassword: (password: string) => void;
  email?: string | undefined | null;
  password?: string | undefined | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<CreateUserCommand, any, CreateUserCommand>;
  onNextStep: () => void;
  onPreviousStep: () => void;
}
export default function SecondPageReg({
  control,
  email,
  password,
  onUpdateEmail,
  onUpdatePassword,
  onNextStep,
  onPreviousStep,
}: SecondPageRegProperties) {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const emailConfig: ValidationConfig = emailValidationConfig(control);
  const {field, fieldState} = useController(emailConfig);

  const passwordConfig: ValidationConfig = passwordValidationConfig(control);
  const {field: fieldPassword, fieldState: fieldStatePassword} = useController(passwordConfig);
  return (
    <div>
      <div
        className={'col-12 row ' + styles.innerWrapper}
        style={{padding: 0, justifyContent: 'center'}}>
        <div className="col-lg-12 col-xl-6">
          <div className={styles.introductionDescription}>{t('LoginDescription')}</div>
        </div>

        <div className="col-lg-6 col-xl-6  m-0 p-0">
          <AuthTextInput
            wrapperClassName="col-12"
            props={emailConfig}
            labelValue="Email"
            placeholder={t('Email')}
            inputValue={email ?? ''}
            className="col-12"
            onUpdate={value => {
              onUpdateEmail(value.toString());
            }}></AuthTextInput>
          <AuthTextInput
            wrapperClassName="col-12"
            props={passwordConfig}
            labelValue="Password"
            placeholder={t('Password')}
            className="col-12"
            onUpdate={value => {
              onUpdatePassword(value.toString());
            }}
            type="password"></AuthTextInput>
        </div>
      </div>
      <button
        className={classBtn.buttonMoveLeft}
        onClick={() => {
          onPreviousStep();
        }}>
        {t('prevStep')}
      </button>
      {email && !fieldState.error && password && !fieldStatePassword.error && (
        <button
          className={classBtn.buttonMoveRight}
          onClick={() => {
            onNextStep();
          }}>
          {t('nextStep')}
        </button>
      )}
    </div>
  );
}

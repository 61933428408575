import React, {ChangeEvent, useEffect, useState} from 'react';

import {faVial} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classes from './themeChangerBtn.module.scss';
import {useTranslation} from 'react-i18next';
export interface ThemeChangerBtnProperties {
  onClickHandler: () => void;
}

export default function ThemeChangerBtn({onClickHandler}: ThemeChangerBtnProperties) {
  const {t} = useTranslation();
  return (
    <>
      <button
        className="desktopOnly"
        onClick={() => {
          onClickHandler();
        }}>
        <FontAwesomeIcon className={classes.themeChangeBtn} icon={faVial} />
      </button>
      <a
        className="mobileTabletOnly mx-2"
        onClick={() => {
          onClickHandler();
        }}>
        {t('Themes')}
      </a>
    </>
  );
}

import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import {DateTime} from 'luxon';

import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../../../hooks/redux';
import styles from './thirdPage.module.scss';
import {AuthTextInput} from '../../../../pages/auth/components/authInputs/authInputs.component';
import {
  dateOfBirthValidationConfig,
  heightValidationConfig,
  emptyControlTextValidationConfig,
  weightValidationConfig,
  simpleTextValidationConfig,
  ValidationConfig,
} from '../../../common/configurations/validationConfigurations';
import {CreateUserCommand} from '../../../../models/accountModel';
import {Control} from 'react-hook-form';
import {AuthSelect} from '../../../../pages/auth/components/authSelect.component';
import {keyValuePair} from '../../../../models/common/keyValuePair';
import classBtn from '../buttonsMove.module.scss';
export interface ThirdPageRegProperties {
  onUpdateHeight: (height: string) => void;
  onUpdateWeight: (weight: string) => void;
  onUpdateBirthOfDate: (birthOfDate: string) => void;
  onUpdateSex: (sex: string) => void;
  height?: string | undefined | null;
  weight?: string | undefined | null;
  birthOfDate?: string | undefined | null;
  sex?: string | undefined | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<CreateUserCommand, any, CreateUserCommand>;
  SignUp: () => void;
  onPreviousStep: () => void;
}
export default function ThirdPageReg({
  control,
  height,
  weight,
  birthOfDate,
  sex,
  onUpdateHeight,
  onUpdateWeight,
  onUpdateBirthOfDate,
  onUpdateSex,
  onPreviousStep,
  SignUp,
}: ThirdPageRegProperties) {
  const {t} = useTranslation();
  const heightConfig: ValidationConfig = heightValidationConfig(control);
  const weightConfig: ValidationConfig = weightValidationConfig(control);

  const dateOfBirthConfig: ValidationConfig = dateOfBirthValidationConfig(control);
  const sexConfig: ValidationConfig = emptyControlTextValidationConfig(control, 'sex');
  const sexData: keyValuePair[] = [
    {key: 0, value: t('SEX_NONE')},
    {key: 1, value: t('SEX_MALE')},
    {key: 2, value: t('SEX_FEMALE')},
  ];
  const dispatch = useAppDispatch();
  return (
    <div className={styles.wrapper}>
      <div
        className={'col-12 row ' + styles.innerWrapper}
        style={{padding: 0, justifyContent: 'center'}}>
        <div className="col-6 col-lg-6 col-xl-6" style={{position: 'relative'}}>
          <div className={styles.introductionDescription}>{t('LastStepDescription')}</div>
          {sex == '1' && <div className={styles.maleBodyLogo}></div>}
          {sex == '2' && <div className={styles.femaleBodyLogo}></div>}
        </div>

        <div className="col-6 col-lg-6 col-xl-6  m-0 p-0">
          <AuthTextInput
            wrapperClassName="col-12 m-0 p-0"
            props={heightConfig}
            labelValue="Height"
            placeholder={t('Height')}
            inputValue={height ?? ''}
            className="col-12"
            onUpdate={value => {
              onUpdateHeight(value.toString());
            }}
            type="number"></AuthTextInput>
          <AuthTextInput
            wrapperClassName="col-12 m-0 p-0"
            props={weightConfig}
            inputValue={weight ?? ''}
            labelValue="Weight"
            placeholder={t('Weight')}
            className="col-12"
            onUpdate={value => {
              onUpdateWeight(value.toString());
            }}
            type="number"></AuthTextInput>
          <AuthTextInput
            wrapperClassName="col-12 m-0 p-0"
            inputValue={birthOfDate ?? ''}
            props={dateOfBirthConfig}
            labelValue="birthOfDate"
            placeholder={t('birthOfDate')}
            className="col-12"
            onUpdate={value => {
              onUpdateBirthOfDate(value.toString());
            }}
            type="date"></AuthTextInput>
          <AuthSelect
            wrapperClassName="col-12"
            // inputValue={form.sex ?? SexEnum.Value0}
            props={sexConfig}
            className="col-12"
            data={sexData}
            onUpdate={data => {
              onUpdateSex(data.toString());
            }}
          />
        </div>
      </div>
      <button
        className={classBtn.buttonMoveLeft}
        onClick={() => {
          onPreviousStep();
        }}>
        {t('prevStep')}
      </button>
      {height && height.length > 0 && weight && weight.length > 0 && (
        <button
          className={classBtn.buttonMoveRight}
          onClick={() => {
            SignUp();
          }}>
          {t('signUp')}
        </button>
      )}
    </div>
  );
}

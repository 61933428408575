import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import {DateTime} from 'luxon';

import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../../../hooks/redux';
import styles from './firstPage.module.scss';
import {AuthTextInput} from '../../../../pages/auth/components/authInputs/authInputs.component';
import {
  simpleTextValidationConfig,
  ValidationConfig,
} from '../../../common/configurations/validationConfigurations';
import {CreateUserCommand} from '../../../../models/accountModel';
import {Control} from 'react-hook-form';
import classBtn from '../buttonsMove.module.scss';
export interface FirstPageRegProperties {
  onUpdateFirstName: (firstName: string) => void;
  onUpdateLastName: (lastName: string) => void;
  firstName?: string | undefined | null;
  lastName?: string | undefined | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<CreateUserCommand, any, CreateUserCommand>;
  onNextStep: () => void;
}
export default function FirstPageReg({
  control,
  firstName,
  lastName,
  onUpdateFirstName,
  onUpdateLastName,
  onNextStep,
}: FirstPageRegProperties) {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const firstNameConfig: ValidationConfig = simpleTextValidationConfig(control, 'firstName');
  const lastNameConfig: ValidationConfig = simpleTextValidationConfig(control, 'lastName');
  return (
    <div className={styles.wrapper}>
      <div
        className={'col-12 row ' + styles.innerWrapper}
        style={{padding: 0, justifyContent: 'center'}}>
        <div className="col-lg-12 col-xl-6">
          <h5 className={styles.title}>{t('WelcomeToTrackoon')}</h5>
          <h6 className={styles.title}>{t('LetsIntroduction')}</h6>
          <div className={styles.introductionDescription}>{t('LetsIntroductionDescription')}</div>
        </div>

        <div className="col-lg-6 col-xl-3  m-0 p-0">
          <AuthTextInput
            wrapperClassName="col-12  m-0 p-0"
            props={firstNameConfig}
            labelValue="First Name"
            inputValue={firstName ?? ''}
            placeholder={t('FirstName')}
            className="col-12 m-0 p-0"
            inputClassName={' m-0 p-0 '}
            onUpdate={value => {
              if (value != null) {
                onUpdateFirstName(value.toString());
              }
            }}></AuthTextInput>
          <AuthTextInput
            wrapperClassName="col-12  m-0 p-0"
            props={lastNameConfig}
            inputValue={lastName ?? ''}
            labelValue="Last Name"
            placeholder={t('LastName')}
            className="col-12  m-0 p-0"
            inputClassName={' m-0 p-0 '}
            onUpdate={value => {
              if (value != null) {
                onUpdateLastName(value.toString());
              }
            }}></AuthTextInput>
        </div>
        <div className={'col-lg-6 col-xl-3 '} style={{display: 'flex', justifyContent: 'center'}}>
          <div className={styles.presentationImage}></div>
        </div>
      </div>
      {firstName && lastName && (
        <button
          className={classBtn.buttonMoveRight}
          onClick={() => {
            onNextStep();
          }}>
          {t('nextStep')}
        </button>
      )}
    </div>
  );
}

import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import {DateTime} from 'luxon';
import {useAppDispatch} from '../../hooks/redux';
import {ScheduledTrainingPlanDto, SessionDto} from '../../models/trainingModel';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AddHistoryIcon from '../common/icon/addHistoryIcon/addHistoryIcon.component';
import {faBowlFood, faDumbbell, faInfo, faXmark} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import classes from './calendarItem.module.scss';
import classColors from '../shared/sharedColorConfigurations.module.scss';
import ModalWrapper from '../common/modal/modalWrapper/modalWrapper.component';
import SummaryComponent from '../trainings/realTimeTraining/manuallyTraining/summary/summary.component';
import {getSession, getSessions} from '../../actions/trainings/session.action';
import {MealDTO} from '../../models/mealModel';
import {Totals} from '../meals/meals/totalMealDayComponent/totalMealDay.component';
import {useTranslation} from 'react-i18next';
export interface CalendarItemProperties {
  day: DateTime;
  today: DateTime;
  clickDeleteHandler?: (id: string) => void;
  trainings: ScheduledTrainingPlanDto[];
  sessions?: SessionDto[] | undefined;
  meals?: MealDTO[] | undefined;
  openAddScheduledTrainingPlanHandler: (day: DateTime) => void;
  onClickShowMeal: (day: DateTime) => void;
  isSelected: boolean;
  onHover: (day: DateTime) => void;
}
export default function CalendarItem({
  day,
  meals,
  today,
  sessions,
  trainings,
  clickDeleteHandler,
  openAddScheduledTrainingPlanHandler,
  isSelected,
  onHover,
  onClickShowMeal,
}: CalendarItemProperties) {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const [selectedSession, setSelectedSession] = useState<SessionDto>();
  const getTotalsForDay = (list?: MealDTO[]) => {
    if (list != null) {
      return list.reduce<Totals>(
        (acc, meal: MealDTO) => {
          acc.proteins += meal.proteins || 0;
          acc.fats += meal.fats || 0;
          acc.carbohydrates += meal.carbohydrates || 0;
          acc.calories += meal.calories || 0;
          return acc;
        },
        {proteins: 0, fats: 0, carbohydrates: 0, calories: 0},
      );
    }
    return {proteins: 0, fats: 0, carbohydrates: 0, calories: 0};
  };
  const drawTotalsMacronutrientsForDay = (list?: MealDTO[]) => {
    const totals = getTotalsForDay(list);
    if (totals.calories != 0) {
      return (
        <div className={classes.wrapperTotal}>
          <div className="mx-2">
            <div>{t('ccal')}</div>
            <div>{Math.round(totals.calories)}</div>
          </div>
          <div className="mx-2">
            <div>{t('fats')}</div>
            <div>{Math.round(totals.fats)}</div>
          </div>

          <div className="mx-2">
            <div>{t('prot')}</div>
            <div>{Math.round(totals.proteins)}</div>
          </div>
          <div className="mx-2">
            <div>{t('carb')}</div>
            <div>{Math.round(totals.carbohydrates)}</div>
          </div>
        </div>
      );
    }
  };
  return (
    <div
      key={day.toISODate()}
      onMouseEnter={() => onHover(day)}
      className={classNames(classes.calendarItem, {
        [classes.calendarItemActive]: day.month === today.month,
        [classes.calendarItemInActive]: day.month !== today.month,
        [classes.calendarItemSelected]: isSelected,
      })}>
      <ModalWrapper
        handleClose={() => {
          setSelectedSession(undefined);
        }}
        isOpen={selectedSession != undefined}>
        <SummaryComponent session={selectedSession}></SummaryComponent>
      </ModalWrapper>
      <div
        className={classNames(classes.dayItem, {
          [classes.dayItemActive]: day.month === today.month,
          [classes.dayItemInActive]: day.month !== today.month,
        })}>
        {day.day}
      </div>
      {sessions?.map(f => {
        return (
          <div
            key={f.id}
            onClick={async () => {
              if (f.id != null) {
                dispatch(getSession(f.id)).then(result => {
                  if (result != undefined) {
                    setSelectedSession(result);
                  }
                });
              }
            }}
            className={classes.infoWrapper + ' mx-1'}>
            <FontAwesomeIcon icon={faInfo}></FontAwesomeIcon>;
          </div>
        );
      })}
      {drawTotalsMacronutrientsForDay(meals)}
      <div className="scheduledTrainingPlanWrapper">
        {trainings?.map(f => {
          return f.plan != null ? (
            <div className="scheduledTrainingPlan">
              {f.plan.name}
              {clickDeleteHandler != null && (
                <span
                  className="color-white"
                  onClick={e => {
                    e.stopPropagation();
                    clickDeleteHandler(f.id!);
                  }}>
                  <FontAwesomeIcon icon={faXmark} />
                </span>
              )}
            </div>
          ) : (
            ''
          );
        })}
      </div>
      <div className={classes.actions}>
        <FontAwesomeIcon
          icon={faDumbbell}
          onClick={() => {
            openAddScheduledTrainingPlanHandler(day);
          }}></FontAwesomeIcon>
        <div>
          <FontAwesomeIcon
            icon={faBowlFood}
            onClick={() => {
              onClickShowMeal(day);
            }}></FontAwesomeIcon>
        </div>
      </div>
    </div>
  );
}

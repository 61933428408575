import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useState} from 'react';
import {useAppDispatch} from '../../../hooks/redux';
import {login, register} from '../../../actions/account/login.actions';
import {useNavigate} from 'react-router-dom';
import {
  CreateUserCommand,
  KcalFormulaEnum,
  LoginUserCommand,
  SexEnum,
} from '../../../models/accountModel';
import MainLogo from '../../../components/common/logos/mainLogo';
import AuthWrapperLogo from '../components/authSocialWrapper.component';
import {AuthButton} from '../components/authButton/authButton.component';
import {useTranslation} from 'react-i18next';
import {
  ErrorOption,
  Field,
  FieldArray,
  FieldArrayPath,
  FieldError,
  FieldErrors,
  FieldName,
  FieldRefs,
  FieldValues,
  FormState,
  InternalFieldName,
  RegisterOptions,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  UseFormRegisterReturn,
} from 'react-hook-form';
import {
  ValidationConfig,
  dateOfBirthValidationConfig,
  emailValidationConfig,
  emptyControlTextValidationConfig,
  heightValidationConfig,
  passwordValidationConfig,
  simpleTextValidationConfig,
  weightValidationConfig,
} from '../../../components/common/configurations/validationConfigurations';
import {AuthTextInput} from '../components/authInputs/authInputs.component';
import {AuthSelect} from '../components/authSelect.component';
import {keyValuePair} from '../../../models/common/keyValuePair';
import AuthPageLayout from '../../../layouts/auth.layout';
import {getRegistrationState} from '../../../actions/account/group.actions';
import FirstPageReg from '../../../components/account/registration/firstPage/firstPage.component';
import SecondPageReg from '../../../components/account/registration/secondPage/secondPage.component';
import ThirdPageReg from '../../../components/account/registration/thirdPage/thirdPage.component';
import Steps from '../../../components/common/steps/steps.component';

export function RegistrationPage() {
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    reset,
    formState: {errors},
    setValue,
    getFieldState,
  } = useForm<CreateUserCommand>({
    mode: 'onBlur',
  });

  const {t} = useTranslation();
  const [form, setForm] = useState<CreateUserCommand>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    birthOfDate: '',
    height: 0,
    weight: 0,
    sex: SexEnum.Value0,
  });

  const [step, setStep] = useState(0);
  const isFormValid = () => {
    // return form.password.trim().length && form.username.trim().length
    return true;
  };

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isFormValid()) {
      await dispatch(register(form));
      // navigate('/')
    } else {
      alert('Form is invalid!');
    }
  };

  const changeHandler = (name: string, value: string | number) => {
    setForm(prev => ({...prev, [name]: value}));
  };

  const registerHandler = async () => {
    await dispatch(register(form));
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const codeParam = queryParams.get('code');
    const emailParam = queryParams.get('email');
    if (codeParam != null && emailParam != null) {
      changeHandler('email', emailParam);
      changeHandler('code', codeParam);
      dispatch(getRegistrationState(emailParam, codeParam)).then(f => {
        if (f?.firstName != null) {
          changeHandler('firstName', f?.firstName);
        }
        if (f?.lastName != null) {
          changeHandler('lastName', f.lastName);
        }
        if (f?.sex != null) {
          changeHandler('sex', f.sex);
        }
      });
    }
  }, []);
  const steps = [{label: 'Introduce'}, {label: 'Login'}, {label: 'Physical'}];

  return (
    <AuthPageLayout>
      <div className="row col-12" style={{maxWidth: 900, margin: 0, padding: 0}}>
        <form onSubmit={handleSubmit(registerHandler)}>
          <div style={{padding: 0, margin: '0px'}}>
            {step == 0 && (
              <FirstPageReg
                onUpdateFirstName={function (firstName: string): void {
                  changeHandler('firstName', firstName);
                }}
                onUpdateLastName={function (lastName: string): void {
                  changeHandler('lastName', lastName);
                }}
                firstName={form.firstName}
                lastName={form.lastName}
                control={control}
                onNextStep={() => {
                  setStep(1);
                }}
              />
            )}
            {step == 1 && (
              <SecondPageReg
                control={control}
                email={form.email}
                password={form.password}
                onUpdateEmail={function (email: string): void {
                  changeHandler('email', email);
                }}
                onUpdatePassword={function (password: string): void {
                  changeHandler('password', password);
                }}
                onPreviousStep={() => {
                  setStep(0);
                }}
                onNextStep={function (): void {
                  setStep(2);
                }}
              />
            )}
            {step == 2 && (
              <ThirdPageReg
                control={control}
                height={form.height?.toString()}
                weight={form.weight?.toString()}
                sex={form.sex?.toString()}
                birthOfDate={form.birthOfDate?.toString()}
                onUpdateHeight={function (height: string): void {
                  try {
                    changeHandler('height', Number.parseFloat(height));
                  } catch (ex) {
                    console.log(ex);
                  }
                }}
                onUpdateWeight={function (weight: string): void {
                  try {
                    changeHandler('weight', Number.parseFloat(weight));
                  } catch (ex) {
                    console.log(ex);
                  }
                }}
                onUpdateBirthOfDate={function (birthOfDate: string): void {
                  changeHandler('birthOfDate', birthOfDate);
                }}
                onUpdateSex={function (sex: string): void {
                  try {
                    changeHandler('sex', Number.parseFloat(sex));
                  } catch (ex) {
                    console.log(ex);
                  }
                }}
                onPreviousStep={() => {
                  setStep(1);
                }}
                SignUp={() => {}}></ThirdPageReg>
            )}
          </div>
          <Steps steps={steps} activeStep={step} />
        </form>
        <div className="row col-12" style={{marginTop: 30, marginBottom: 30}}>
          <div className="col-12 text-center">
            <span>
              <a href="signin"> {t('have_acount_redirect_login')} </a>
            </span>
          </div>
        </div>
      </div>
    </AuthPageLayout>
  );
}

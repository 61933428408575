import React, {useEffect, useState} from 'react';
import {MealDTO} from '../../../../models/mealModel';
import {useAppDispatch} from '../../../../hooks/redux';
import {MealComponent} from '../mealComponent/mealComponent';
import classes from './mealsManager.module.scss';
import {useTranslation} from 'react-i18next';
import {DateTime} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import ScrollableBody from '../../../common/scrollbar/scrollbar.component';
import classColor from '../../../shared/sharedColorConfigurations.module.scss';
import ConfirmModal from '../../../common/modal/confirmAction.component';
import {copyMeal, deleteMeal, updateMeal} from '../../../../actions/meals/meal.action';
import TotalMealDayComponent from '../totalMealDayComponent/totalMealDay.component';
import {UserInfoDTO} from '../../../../models/accountModel';
import {userInfo} from 'os';
import {createTotals} from '../../../../utils/mealUtil';
import {AddEditFoodMealComponent} from '../../foodMeals/addEditFoodMeal/AddEditFoodMealComponent';
import ModalWrapper from '../../../common/modal/modalWrapper/modalWrapper.component';
import AddEditMealComponent from '../addEditMealComponent/addEditMealComponent';
import {selectCurrentUserId} from '../../../../store/account/slices/login.slice';
import {useSelector} from 'react-redux';
import classesShared from '../../../shared/sharedComponent.module.scss';
import {shortCommonTimeFormat} from '../../../../constants/common/dateFormat';
import {
  CreateMealObject,
  CreateUpdateFoodMealObject,
} from '../../../../models/strict/mealStrictModel';
import classNames from 'classnames';
import MealItemManager from './mealItemManager/mealItemManager.component';
import MealsManagerBody from './mealsManagerBody/mealsManagerBody.component';

export interface MealsManagerProperties {
  list: MealDTO[];
  user: UserInfoDTO;
  weight?: number | undefined;
  isShordivate?: boolean;
  onAddMealHandler: () => void;
  callRefresh: () => void;
}
export default function MealsManager({
  list,
  user,
  weight,
  callRefresh,
  onAddMealHandler,
}: MealsManagerProperties) {
  const dispatch = useAppDispatch();
  const userId = useSelector(selectCurrentUserId);
  const [meals, setMeals] = useState<MealDTO[]>(list);
  const [modMeal, setModMeal] = useState<MealDTO>();
  const columns = 4; // Adjust this based on your desired column count
  const placeholdersNeeded = Math.max(0, columns - (meals.length % columns) - 1);
  const [totalCalories, setTotalCallories] = useState<number>();
  const [eatedCalories, setEatedCallories] = useState<number>();
  const [deletedMealId, setDeletedMealId] = useState<string>();
  useEffect(() => {
    if (meals) {
      {
        setEatedCallories(
          Math.round(
            meals
              .filter(
                f =>
                  f.eatTime != null &&
                  DateTime.fromISO(f.eatTime).startOf('day') == DateTime.now().startOf('day'),
              )
              .reduce((accumulator, object) => {
                return accumulator + object.calories!;
              }, 0),
          ),
        );
      }
    }
  }, [meals]);
  useEffect(() => {
    setMeals(list);
  }, [list]);
  const copyMealClickHandler = (mealId: string) => {
    dispatch(copyMeal(mealId)).then(() => {
      callRefresh();
    });
  };
  const {t} = useTranslation();
  function renderMealList() {
    return (
      <>
        <ScrollableBody
          maxHeight="300px"
          minHeight="300px"
          height="300px"
          className={classes.customScrollable}>
          <ModalWrapper
            handleClose={function () {
              setModMeal(undefined);
            }}
            isOpen={modMeal != null}>
            <AddEditMealComponent
              meal={modMeal!}
              saveHandler={function (meal: MealDTO) {
                const requestDTO = new CreateMealObject(
                  meal.id,
                  meal.userId,
                  meal.eatTime,
                  meal.foods?.map(
                    f =>
                      new CreateUpdateFoodMealObject(
                        f.id,
                        f.mealId,
                        f.food?.id ? f.food.id : undefined,
                        f.weight,
                      ),
                  ),
                );
                dispatch(updateMeal(requestDTO)).then(f => {
                  setModMeal(undefined);
                  callRefresh();
                });
              }}
            />
          </ModalWrapper>
          {meals.map((meal, mealIndex) => (
            <MealItemManager
              onCopyMealClickHandler={copiedMeal => {
                copyMealClickHandler(copiedMeal.id!);
              }}
              onDeleteMealClickHandler={deletedMeal => {
                setDeletedMealId(deletedMeal.id!);
              }}
              key={mealIndex}
              meal={meal}
              onEditMealClickHandler={editedMeal => {
                setModMeal(editedMeal);
              }}></MealItemManager>
          ))}
        </ScrollableBody>
      </>
    );
  }

  return (
    <>
      <ConfirmModal
        handleAgree={function () {
          if (deletedMealId != null) {
            dispatch(deleteMeal(deletedMealId))
              .then(f => {
                callRefresh();
              })
              .finally(() => {
                setDeletedMealId(undefined);
              });
          }
        }}
        handleClose={function (): void {
          setDeletedMealId(undefined);
        }}
        isOpen={deletedMealId != undefined}></ConfirmModal>
      {list.length > 0 && (
        <h4
          className={classColor.sidebarColor + ' offset-sm-2 col-2  mb-2'}
          style={{
            color: 'var( --textColor)',
            borderBottom: '2px var(--primaryColor) solid',
            width: '150px',
            textAlign: 'left',
            paddingLeft: 0,
            position: 'absolute',
            top: 0,
            left: 20,
          }}>
          {t('MealsForToday')}

          {/* <a className={classesShared.historicWrapper} href={'./history/' + userId}>
          <FontAwesomeIcon width={30} height={30} icon={faTimeline}></FontAwesomeIcon>
        </a> */}
        </h4>
      )}

      <div
        className="row offset-md-1 col-xl-12 col-md-10 mt-4 mb-4"
        style={{display: 'flex', justifyContent: 'center', margin: 0, padding: 0}}>
        <div
          className={
            list && list.length > 0 && weight
              ? 'blockBackgroundWrapperWrapper col-lg-12 col-xl-5 mb-4 mt-4 pt-4'
              : 'col-lg-12 mb-4 mt-4 pt-4'
          }
          style={{position: 'relative', height: 'fit-content'}}>
          <button
            onClick={onAddMealHandler}
            className={classNames(classes.mealAdd, {
              [classes.mealAddExist]: list.length > 0,
            })}>
            {t('AddMeal')}
          </button>
          <MealsManagerBody
            list={list}
            callRefresh={() => {
              callRefresh();
            }}></MealsManagerBody>
        </div>
        {weight && list.length > 0 && (
          <div className="  col-lg-12  col-xl-5">
            <TotalMealDayComponent
              list={list}
              total={createTotals(user, weight)}></TotalMealDayComponent>
          </div>
        )}
      </div>
    </>
  );
}

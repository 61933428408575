import axios from 'axios';
import {
  selectCurrentUserToken,
  ACCESS_KEY,
  selectCurrentUserRefresh,
  REFRESH_KEY,
} from '../store/account/slices/login.slice';
import {useSelector} from 'react-redux';
import {useReducer, useTransition} from 'react';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import i18n from '../i18n/config';
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://129.151.205.70',
});

let token = localStorage.getItem(ACCESS_KEY);
axiosInstance.interceptors.request.use(req => {
  if (token == null) {
    token = localStorage.getItem(ACCESS_KEY);
  }
  if (token != null) {
    req.headers.Authorization = 'Bearer ' + token;
  }
  return req;
});
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log(error);
    if (error.code === 'ERR_NETWORK') {
      toast.error('Problem with network, or server, please try again later.');
    } else if (error.response && error.response.status === 401) {
      const refreshToken = useSelector(selectCurrentUserRefresh);
      console.log(refreshToken);
      if (refreshToken) {
        return axios
          .post(`${process.env.REACT_APP_API_URL}/auth/refresh`, {refreshToken})
          .then(response => {
            const {accessToken, refreshToken: newRefreshToken} = response.data;

            localStorage.setItem(ACCESS_KEY, accessToken);
            localStorage.setItem(REFRESH_KEY, newRefreshToken);

            error.config.headers.Authorization = `Bearer ${accessToken}`;

            return axiosInstance.request(error.config);
          })
          .catch(refreshError => {
            localStorage.removeItem(ACCESS_KEY);
            localStorage.removeItem(REFRESH_KEY);
            location.href = '/signin';
            return Promise.reject(refreshError);
          });
      } else {
        localStorage.removeItem(ACCESS_KEY);
        localStorage.removeItem(REFRESH_KEY);
        location.href = '/signin';
      }
    } else if (
      error.response &&
      error.response.data?.errorMessageCode != null &&
      error.response.data?.microServiceCode != null
    ) {
      if (
        `${error.response.data.errorMessageCode}_${error.response.data.microServiceCode}` == '1_3'
      ) {
        const email = document.getElementsByName('Email')[0] as HTMLInputElement;
        location.href = '/notConfirmedEmail?email=' + email.value;
      } else {
        console.log('hello, i`ma erorr');
        toast.error(
          i18n.t(
            `ERROR_${error.response.data.errorMessageCode}_${error.response.data.microServiceCode}`,
          ),
        );
      }
    } else if (error.response.data != null) {
      toast.error(i18n.t(`ERROR_${error.response.data}`));
    } else {
      if (error.response.status === 500) {
        toast.error('An error occurred');
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;

import React from 'react';
import classes from './steps.module.scss'; // Import SCSS Modules file for styles

interface StepsProps {
  steps: {label: string}[];
  activeStep: number;
}

const Steps: React.FC<StepsProps> = ({steps, activeStep}) => {
  return (
    <div className={classes.stepGraphContainer}>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div className={`${classes.step} ${index === activeStep ? classes.stepActive : ''}`}>
            <div className={classes.stepCircle}>{index + 1}</div>
            <div className={classes.stepLabel}>{step.label}</div>
          </div>
          {index < steps.length - 1 && <div className={classes.stepLine} />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Steps;

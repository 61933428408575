import {Route, Navigate} from 'react-router-dom';
import {LoginPage} from '../pages/auth/login.page';
import React from 'react';
import {RegistrationPage} from '../pages/auth/registration/registration.page';
import {EmailConfirmPage} from '../pages/auth/emailConfirmation.page';
import {EmailIsNotVerifiedPage} from '../pages/auth/emailIsNotVerified.page';
import {ForgotPasswordPage} from '../pages/auth/forgotPassword.component';
import {ResetPasswordPage} from '../pages/auth/resetPassword.page';
import {useAppSelector} from '../hooks/redux';
import MainPage from '../pages/main/main.page';
import PrivacyPage from '../pages/common/privacyPage.page';

interface PublicRouteProps {
  element: React.ReactElement;
}
const PublicRoute = ({element}: PublicRouteProps) => {
  const {isAuthenticated} = useAppSelector(state => state.authReducer);

  return !isAuthenticated ? element : <Navigate to="/" />;
};
const AnonymousRoutes = [
  <Route
    key="SignIn"
    path="/signin"
    element={<PublicRoute element={<LoginPage />}></PublicRoute>}
  />,
  <Route
    key="SignIn"
    path="/login"
    element={<PublicRoute element={<LoginPage />}></PublicRoute>}
  />,
  <Route
    key="TestMain"
    path="/TestMain"
    element={<PublicRoute element={<MainPage />}></PublicRoute>}
  />,
  <Route
    key="Privacy"
    path="/privacy"
    element={<PublicRoute element={<PrivacyPage />}></PublicRoute>}
  />,
  <Route
    key="Registration"
    path="/registration"
    element={<PublicRoute element={<RegistrationPage />}></PublicRoute>}
  />,
  <Route
    key="Registration"
    path="/signUp"
    element={<PublicRoute element={<RegistrationPage />}></PublicRoute>}
  />,
  <Route
    key="ConfirmEmail"
    path="/confirmEmail"
    element={<PublicRoute element={<EmailConfirmPage />}></PublicRoute>}
  />,
  <Route
    key="NotConfirmedEmail"
    path="/notConfirmedEmail"
    element={<PublicRoute element={<EmailIsNotVerifiedPage />}></PublicRoute>}
  />,
  <Route
    key="forgotPassword"
    path="/forgotPassword"
    element={<PublicRoute element={<ForgotPasswordPage />}></PublicRoute>}
  />,
  <Route
    key="resetPassword"
    path="/resetPassword"
    element={<PublicRoute element={<ResetPasswordPage />}></PublicRoute>}
  />,
];
export default AnonymousRoutes;

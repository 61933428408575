import React, {useEffect, useState} from 'react';
import {MealDTO} from '../../../../../models/mealModel';
import {useAppDispatch} from '../../../../../hooks/redux';
import {MealComponent} from '../../mealComponent/mealComponent';
import classes from './mealsManagerBody.module.scss';
import {useTranslation} from 'react-i18next';
import {DateTime} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import ScrollableBody from '../../../../common/scrollbar/scrollbar.component';
import classColor from '../../../../shared/sharedColorConfigurations.module.scss';
import ConfirmModal from '../../../../common/modal/confirmAction.component';
import {copyMeal, deleteMeal, updateMeal} from '../../../../../actions/meals/meal.action';
import TotalMealDayComponent from '../../totalMealDayComponent/totalMealDay.component';
import {UserInfoDTO} from '../../../../../models/accountModel';
import {userInfo} from 'os';
import {createTotals} from '../../../../../utils/mealUtil';
import {AddEditFoodMealComponent} from '../../../foodMeals/addEditFoodMeal/AddEditFoodMealComponent';
import ModalWrapper from '../../../../common/modal/modalWrapper/modalWrapper.component';
import AddEditMealComponent from '../../addEditMealComponent/addEditMealComponent';
import {selectCurrentUserId} from '../../../../../store/account/slices/login.slice';
import {useSelector} from 'react-redux';
import {
  CreateMealObject,
  CreateUpdateFoodMealObject,
} from '../../../../../models/strict/mealStrictModel';
import MealItemManager from '../mealItemManager/mealItemManager.component';

export interface MealsManagerBodyProperties {
  list: MealDTO[];
  callRefresh: () => void;
}
export default function MealsManagerBody({list, callRefresh}: MealsManagerBodyProperties) {
  const dispatch = useAppDispatch();
  const [meals, setMeals] = useState<MealDTO[]>(list);
  const [modMeal, setModMeal] = useState<MealDTO>();
  const columns = 4; // Adjust this based on your desired column count
  const placeholdersNeeded = Math.max(0, columns - (meals.length % columns) - 1);
  const [eatedCalories, setEatedCallories] = useState<number>();
  const [deletedMealId, setDeletedMealId] = useState<string>();
  useEffect(() => {
    if (meals) {
      {
        setEatedCallories(
          Math.round(
            meals
              .filter(
                f =>
                  f.eatTime != null &&
                  DateTime.fromISO(f.eatTime).startOf('day') == DateTime.now().startOf('day'),
              )
              .reduce((accumulator, object) => {
                return accumulator + object.calories!;
              }, 0),
          ),
        );
      }
    }
  }, [meals]);
  useEffect(() => {
    setMeals(list);
  }, [list]);
  const copyMealClickHandler = (mealId: string) => {
    dispatch(copyMeal(mealId)).then(() => {
      callRefresh();
    });
  };
  const {t} = useTranslation();
  function renderMealList() {
    return (
      <>
        <ScrollableBody
          maxHeight="300px"
          minHeight="300px"
          height="300px"
          className={classes.customScrollable}>
          <ModalWrapper
            handleClose={function () {
              setModMeal(undefined);
            }}
            isOpen={modMeal != null}>
            <AddEditMealComponent
              meal={modMeal!}
              saveHandler={function (meal: MealDTO) {
                const requestDTO = new CreateMealObject(
                  meal.id,
                  meal.userId,
                  meal.eatTime,
                  meal.foods?.map(
                    f =>
                      new CreateUpdateFoodMealObject(
                        f.id,
                        f.mealId,
                        f.food?.id ? f.food.id : undefined,
                        f.weight,
                      ),
                  ),
                );
                dispatch(updateMeal(requestDTO)).then(f => {
                  setModMeal(undefined);
                  callRefresh();
                });
              }}
            />
          </ModalWrapper>
          {meals.map((meal, mealIndex) => (
            <MealItemManager
              onCopyMealClickHandler={copiedMeal => {
                copyMealClickHandler(copiedMeal.id!);
              }}
              onDeleteMealClickHandler={deletedMeal => {
                setDeletedMealId(deletedMeal.id!);
              }}
              key={mealIndex}
              meal={meal}
              onEditMealClickHandler={editedMeal => {
                setModMeal(editedMeal);
              }}></MealItemManager>
          ))}
        </ScrollableBody>
      </>
    );
  }

  return (
    <>
      <ConfirmModal
        handleAgree={function () {
          if (deletedMealId != null) {
            dispatch(deleteMeal(deletedMealId))
              .then(f => {
                callRefresh();
              })
              .finally(() => {
                setDeletedMealId(undefined);
              });
          }
        }}
        handleClose={function (): void {
          setDeletedMealId(undefined);
        }}
        isOpen={deletedMealId != undefined}></ConfirmModal>
      {list.length > 0 && (
        <div className={classes.mealsWrapper}>{list && list.length > 0 && renderMealList()}</div>
      )}
    </>
  );
}
